<template>
  <div class="app-container">
    <el-form ref="orderForm" :model="orderForm" label-width="80px">
      <el-form-item label="用户ID">
        <el-input
          v-model="orderForm.user_entity.id"
          disabled
          style="width:50px"
        ></el-input>
      </el-form-item>
      <el-form-item label="商品ID">
        <el-input
          v-model="orderForm.goods_entity.id"
          disabled
          style="width:50px"
        ></el-input>
      </el-form-item>
      <el-form-item label="购买数量">
        <el-input
          v-model="orderForm.amount"
          style="width:80px"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="订单状态">
        <el-radio-group v-model="orderForm.state">
          <el-radio-button :label="0" class="tj">
            提交
          </el-radio-button>
          <el-radio-button :label="2" class="cl">
            处理
          </el-radio-button>
          <el-radio-button :label="1" class="wc">
            完成
          </el-radio-button>
          <el-radio-button :label="-1" class="gb">
            关闭
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="备注">
        <el-input type="textarea" v-model="orderForm.remark"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">修改</el-button>
        <el-button @click="closeDialog">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { editOrderValue } from '@/api/shopping.js'

export default {
  props: {
    orderValue: {
      type: Object
    }
  },
  data() {
    return {
      orderForm: {}
    }
  },
  watch: {
    orderValue(orderValue) {
      this.orderForm = this.orderValue
    }
  },
  created() {
    this.orderForm = this.orderValue
  },
  methods: {
    async onSubmit() {
      // this.orderForm.state = parseInt(this.orderForm.state)
      await editOrderValue(this.orderForm.id, this.orderForm)
      this.closeDialog()
    },
    // 取消
    closeDialog() {
      this.$refs.orderForm.resetFields()
      this.$emit('closedialog', false)
    }
  }
}
</script>

<style scoped>
::v-deep .wc .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background-color: #67c23a;
  border-color: #67c23a;
  box-shadow: -1px 0 0 0 #67c23a;
}
::v-deep .tj .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background-color: #409eff;
  border-color: #409eff;
  box-shadow: -1px 0 0 0 #409eff;
}
::v-deep .cl .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background-color: #e6a23c;
  border-color: #e6a23c;
  box-shadow: -1px 0 0 0 #e6a23c;
}
::v-deep .gb .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background-color: #f56c6c;
  border-color: #f56c6c;
  box-shadow: -1px 0 0 0 #f56c6c;
}
</style>
